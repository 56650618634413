

.pendientes__table {
   min-width: none; 
}

.pendientes__table-tr td {
    color: red !important;
}




@media (max-width: 600px) {
    .table > :not(caption) > * > * {
        font-size: 12px;
    }
  }


