.table__table > :not(caption) > * > * {
    padding-bottom: 5px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    margin: auto;
    color: #FFF;
    border: lighten($color: $secondary, $amount: 10) 1px solid;
}

.table__table > tbody > tr > td {
    margin-bottom: 15px;
}

.table__table tbody {
    border-radius: 20px
}

.table__detallesBtn {
    font-size: 20px;
    cursor: pointer;
    transition: transform .4s ease;

    &:hover {
        transform: scale(1.15);
    }
}

.table__entregableSi {
    font-size: 20px;
    color: green;
}

.table__entregableNo {
    font-size: 20px;
    color: black;
}

.table__entregableIncompleto {
    font-size: 20px;
    color: #d0d000;
}

.table__entregableNuevo {
    font-size: 20px;
    color: #0007d0;
}

.table__enTiempoNo {
    font-size: 20px;
    color: #d00000;
}

.table__sinComprobar {
    font-size: 20px;
    color: #363636;
}

.table__tableStats {
    width: 80%;
    margin-left: 10%;
}

.table__tableStats > :not(caption) > * > * {
    padding-bottom: 5px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0;
    // border: lighten($color: $secondary, $amount: 10) 1px solid;
}

.table__progressBar {
    display: block;
}

.table__tableVendedor td th{
    padding: 0;
}

.table__progressNumber {
    display: none;
}

.table__sinEntregar {
    width: 50%;
    margin-left: 25%;
    padding-bottom: 200px;
}

.table__noCotejados {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 50px 0;
}

@media (max-width: 600px) {
    .table > :not(caption) > * > * {
      font-size: 10px;
    }

    .table__tableStats {
        width: 90%;
        margin-left: 5%;
    }

    .table__sinEntregar {
        width: 50%;
        margin-left: 25%;
    }
    
}

@media (max-width: 480px) {
    .table > :not(caption) > * > * {
      font-size: 8px;
      min-height: 1000px;
    }

    .table__ocultar {
        display: none;
    }

    .table__tableStats {
        width: 100%;
        margin-left: 0;
    }

    .table__progressBar {
        display: none;
    }

    .vendedor__progressBar {
        display: block !important;
    }
    
    .table__progressNumber {
        display: block;
    }

    .table__sinEntregar {
        width: 60%;
        margin-left: 20%;
    }
    
}





