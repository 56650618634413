
.generate__container {
    width: 50%;
    margin-left: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 25px;

}       

.generate__noRegAviso {
    width: 50%;
    margin-left: 25%;
    background-color: $primary;
    color: #FFFFFF;
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
}

.generate__sectionName {
    font-size: 12px;
}

hr {
    height: 2px;
    color: darken($color: $light-gray, $amount: 10);
}

.generate__form {
    width: 100%;
}

.generate__form-section {
    display: flex;
    flex-direction: column;
}

.generate__form-section div {
    display: flex;
    justify-content: center;
    align-items: center;
}


.generate__form-section > p {
    display: block;
}

.generate__label {
    font-size: 14px;
    font-weight: bold;
    margin-right: 30px;
    width: 60%;
    color: darken($color: $primary, $amount: 20);
}   

.generate__input {
    color: $dark-gray;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid $secondary;
    font-size: 14px;
    margin-bottom: 20px;
    height: 30px;
    transition: border-bottom .3s ease;
    padding: 5px;

    &:focus {
        outline: none;
        border-bottom: 2px solid $primary;
    }
}

.generate__inputSelect {
    width: 20%;
    height: 25px;
    font-size: 14px;
}

.generate__pedido-elements {
    margin-top: 20px;
}

.generate__textbox {
    width: 100%;
    min-height: 100px;
    padding: 5px 10px 5px 10px;
}

.generate__textboxComentarios {
    width: 100%;
    min-height: 50px;
    padding: 5px 10px 5px 10px;
}

.generate__input-file {
    width: 100%;
}

.generate__input-limite {
    width: 100%;
}

.generate__label-pago {
    font-size: 14px;
    font-weight: bold;
    margin-right: 30px;
    width: 100%;
    color: darken($color: $primary, $amount: 20);
}

.generate__factura {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: red;
}

.generate__labeledSelect {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.generate__label-selects {
    font-size: 14px;
    font-weight: bold;
    color: darken($color: $primary, $amount: 20);
    margin: 0;
}

.generate__pagoSelect {
    width: 25%;
    height: 25px;
    font-size: 14px;
}


.generate__label-pago select {
    width: 60%;
}

.generate__pago-selects {
    margin-bottom: 20px;
}

.generate__form-section .generate__pago-selects label {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.generate__factura-inputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding-left: 10%;
    margin-top: 20px;
}

.generate__labelFactura {
    font-size: 14px;
    font-weight: bold;
    width: 60%;
    color: darken($color: $primary, $amount: 20);
}   

.generate__inputFactura {
    color: $dark-gray;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid $light-gray;
    font-size: 14px;
    margin-bottom: 20px;
    height: 30px;
    transition: border-bottom .3s ease;
    padding: 5px;

    &:focus {
        outline: none;
        border-bottom: 2px solid $primary;
    }
}

.generate__form-section-asignar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.generate__btn {
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    padding: 7px 10px 7px 10px;
    transition: background-color 0.4s ease;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: darken($color: $primary, $amount: 15);
    }
}

.generate__btn-primary {
    background-color: $primary;
    border-radius: 2px;
    width: 50%;
    margin-left: 25%;
    margin-bottom: 10px;
    margin-top: 20px;
    align-self: center;

    &:disabled {
        background-color: lighten($color: $primary, $amount: 20);
    }
}

.generate__selectValidation {
    display: flex;
    flex-direction: column;
}

.generate__error {
    color: red;
    margin-top: -15px;
    font-size: 12px;
    margin-left: 5px;
    font-weight: bold;
    text-align: left !important;
}

.generate__rangoTipo {
    justify-content: space-around !important;
}

@media (max-width: 1200px) {

    .generate__container {
        width: 60%;
        margin-left: 20%;
    }  

}

@media (max-width: 1000px) {

    .generate__container {
        width: 75%;
        margin-left: 12.5%;
    }  

}

@media (max-width: 800px) {

    .generate__container {
        width: 90%;
        margin-left: 5%;
    }  

}

@media (max-width: 600px) {

    .generate__container {
        width: 100%;
        margin-left: 0;
    }  

    .generate__noRegAviso {
        width: 80%;
        margin-left: 10%;
    }

    .generate__form-section div {
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .generate__noMT {
        margin-top: 0 !important;
    }

    .generate__sectionName {
        margin-bottom: 0;
    }

}

@media (max-width: 480px) {

    .generate__noRegAviso {
        width: 90%;
        margin-left: 5%;
        font-size: 12px;
    }

    .generate__label {
        width: 100%;
    }

    .generate__input, .generate__inputFactura {
        margin-bottom: 0;
    }

    .generate__error {
        margin-top: 0 !important;
    }

    .generate__form-section div {
        justify-content: flex-start;
    }

    .generate__rangoTipo {
        justify-content: flex-start !important;
    }
    

}
