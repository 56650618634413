.modalPagos__label-selects {
    font-size: 20px !important;
}

.modalPagos__selectInput {
    font-size: 16px !important;
}

.modalPagos__factura-inputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding-left: 35%;
    // margin-top: 20px;
    // background-color: aqua;
    margin-bottom: 30px;
}

.modalPagos__pedido-elements {
    justify-content: flex-start !important;
}

.modalPagos__labelFactura {
    width: 50% !important;
    margin-top: 20px;
}



@media (max-width: 480px) {
    .modalPagos__factura-inputs {
        padding-left: 20%;
    }

    .metodoSelect {
        margin-top: 20px;
    }


}