.settings__title {
    text-align: center;
    color: $primary;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.settings__selectInput {
    font-size: 14px;
    // margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid $secondary;
    color: $dark-gray;
    padding: 5px;

    &:focus {
        outline: none;
        border-bottom: 2px solid $primary;   
     }
}

.generalSettings__noItemsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.generalSettings__noItemsContainer {
    font-size: 18px;
    text-align: center;
}

.generalSettings__loadingContainer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
}

.generalSettings__loadingBody {
    width: 200px;
    height: 50px;
  
    border-radius: 10px;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.generalSettings__loadingBody p {
    font-size: 16px;
    margin: 0;
}

