
.navbar__navbar {
    width: 100vw;
    height: 100px;
    background-color: $secondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
}   

.navbar__logo {
    width: 100px;
    margin-left: 10%;
}

.navbar__menu {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
}

.navbar__menu > li {
    margin-right: 20px;
    list-style: none;
}

.navbar__menu > li > a {
    text-decoration: none;
    color: #000000;
    font-weight: 700;
    transition: color 0.3s ease;

    &:hover {
        color: $primary;
    }
}

.navbar__userInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    background-color: lighten($color: $primary, $amount: 20);
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border-radius: 5px;
}

.navbar__userInfo > p {
    font-size: 14px;
    margin: 0;
}

.navbar__logoutButton {
    color: red;
    font-size: 12px;
}

.navbar-nav {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.nav-link {
    color: #000000;
    font-size: 16px;
}

.active {
    color: $primary;
}