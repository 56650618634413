.auth__main {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: darken($color: $secondary, $amount: 15);
    margin: 0px;
    height: 100vh;
    width: 100vw;
}

.auth__imgContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.auth__logo {
    width: 150px;
}

.auth__box-container {
    background-color: #FFFFFF;
    border-radius: 2px;
    padding: 20px;
    width: 350px;
    min-height: 600px;
    box-shadow: $dark-gray 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.auth__title {
    color: $primary;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.auth__input {
    color: $dark-gray;
    border: 0px;
    border-bottom: 1px solid $light-gray;
    font-size: 16px;
    margin-bottom: 20px;
    height: 30px;
    width: 100%;
    transition: border-bottom .4s ease;
    padding: 5px;

    &:focus {
        outline: none;
        border-bottom: 2px solid $primary;
    }
}

.btn-login {
    margin-top: 20px;
}

.auth__newUser {
    width: 100%;
    padding: 0 auto;
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    color: $primary;

    &:hover {
        color: darken($color: $primary, $amount: 15);
        text-decoration: underline;
    }
}

.auth__newUserCliente {
    background-color: $secondary;
    color: #000000;
    margin-top: 50px;
    margin-left: 10%;
    width: 80%;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px;

    &:hover {
        background-color: darken($color: $secondary, $amount: 15);
        color: #FFFFFF
    }
}


.auth__alert-error {
    background-color: red;
    border-radius: 5px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
}

.auth__error {
    color: red;
    font-size: 12px;
    margin-top: -15px;
    margin-left: 5px;
}

.auth__firebase-error {
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
    background-color: red;
}