

.modal__backgroundModal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(215, 210, 210, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__container {
    width: 600px;
    height: 90vh;
    border-radius: 12px;
    background-color: #FFF;
    box-shadow: rgba(0, 0 ,0, 0.35) 0 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    padding-bottom: 50px;
    overflow-y: initial !important;
    overflow: hidden;
}

.modal__body {
    overflow-y: auto;
    background-color: #fcfcfc;
}

.modal__closeModalXButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modal__closeModalXButton > button {
    width: 5%;
    background-color: #f10000;
    color: #FFF;
    transition: background-color 0.3s ease;
    border-radius: 5px;

    &:hover {
        background-color: darken($color: #f10000, $amount: 10);
    }

}

.modal__title {
    font-size: 25px;
    margin-bottom: 20px;
}

.modal__actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.modal__asignadoBtnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__eliminarBtnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.modal__btnAsignar {
    background-color: darken($color: $primary, $amount: 15);
    color: #FFF;
    width: 30%;
    margin: 0 5px 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: $primary, $amount: 25);
    }
}

.modal__btnModificar {
    background-color: #d6d62b;
    width: 30%;
    margin: 0 5px 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: #d6d62b, $amount: 10);
    }
}

.modal__btnIncompleto {
    background-color: #838383;
    width: 30%;
    margin: 0 5px 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: #838383, $amount: 10);
    }
}

.modal__btnParcial {
    background-color: #ed9c07;
    width: 30%;
    margin: 0 5px 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: #ed9c07, $amount: 10);
    }
}

.modal__btnEliminar {
    background-color: #ed2607;
    color: #FFF;
    width: 30%;
    margin: 0 5px 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: #ed2607, $amount: 10);
    }
}



.modal__parcialObservaciones {
    padding: 5px;
    font-size: 14px;
    width: 80%;
    min-height: 100px;
}

.modal__imagenesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modal__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.modal__body p {
    margin: 0;
    font-size: 14px;
}

.modal__separador {
    border: 0.2px solid $light-gray;
    margin: 5px 0 5px 0;
    box-sizing: border-box;
}

.modal__inputNumeroPedido {
    width: 80%;
    font-size: 14px;
    padding: 5px;

}

.modal__evidenciaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#modal__evidenciaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

#modal__evidenciaContainer label {
    font-size: 16px;
    color: $primary;
}

#modal__cantidadLabel {
    margin-top: 16px;
}

#cantidad {
    font-size: 14px;
    text-align: center;
}

#metodo {
    font-size: 14px;
    margin-top: 24px;
}

#metodo option {
    font-size: 14px;
}

#imagen-completarPedido {
    margin-top: 5px;
    font-size: 13px;
}



@media (max-width: 600px) {

    .modal__title {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .modal__closeModalXButton > button {
        width: 10%;
    }

}

@media (max-width: 480px) {

    .modal__title {
        font-size: 14px;
    }


    .modal__body p {
        font-size: 12px;
    }

    .modal__btnAsignar {
        font-size: 12px;
        width: 40%;
    }
    
    .modal__btnModificar {
        font-size: 12px;
        width: 40%;
    }

    .modal__btnIncompleto {
        font-size: 12px;
        width: 40%;
    }

    .modal__btnEliminar {
        font-size: 12px;
        width: 40%;
    }
    
    .modal__btnParcial {
        font-size: 12px;
        width: 40%;
    }

}

