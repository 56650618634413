

.btn-custom {
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    padding: 7px 10px 7px 10px;
    transition: background-color 0.4s ease;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: darken($color: $primary, $amount: 15);
    }
}

.btn {
    margin: 10px;
}

.btn-primary-custom {
    background-color: $primary;
    border-radius: 2px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 20px;

    &:disabled {
        background-color: lighten($color: $primary, $amount: 20);
    }
}

.btn__detalles {
    background-color: darken($color: $primary, $amount: 15);
    color: #FFFFFF;
    font-size: 14px;
    padding: 2px 7px 2px 7px;

    &:hover {
        background-color: darken($color: $primary, $amount: 25);
    }
}

.btn__sinRegistro {
    background-color: #9f6a06;
    color: #FFFFFF;
    border-radius: 5px;
    width: 80%;
    margin-left: 10%;
    margin-top: 15px;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        background-color: darken($color: #9f6a06, $amount: 10);
    }
}

.btn__modificarMetaComision {
    width: 70px;
    background-color: #daed0b;

    &:hover {
        background-color: darken($color: #daed0b, $amount: 10);
    }
}