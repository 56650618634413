.stadistics__completosContainer {
    // border: 2px solid #000;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin: 20px;
    margin-top: 50px;
    background-color: darken($color: $primary, $amount: 20);
    width: 80%;
    margin-left: 10%;
}

.stadistics__completosContainer p {
    margin: 0;
    color: #fff;
}

.stadistics__completosHeader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stadistics__completosTitle {
    font-size: 20px;
    padding-top: 5px;
}

.stadistics__completosBody {
    display: flex;
    justify-content: space-around;
    align-items:center;
    height: 100px;
}

.stadistics__completosBodyField {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.stadistics__completosBodyFieldTitle {
    font-size: 20px;
    color: $primary;
}

.stadistics__completosBodyFieldData {
    font-size: 20px;
}

.stadistics__completosFooter {
    background-color: #008700;
}

.stadistics__rangoContainer {
    background-color: rgb(210, 208, 208);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
}


.stadistics__rangoFechas {
    width: 20%;
}

.stadistics__input-limite {
    width: 100%;
}

.stadistics__FiltrarBtn {
    background-color: darken($color: $primary, $amount: 15);
    color: #FFF;
    width: 100px;
    margin: 20px 5px 0 -60px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: $primary, $amount: 25);
    }
}

.stadistics__FiltrarNoPagados {
    background-color: #a40303;
    color: #FFF;
    width: 100px;
    margin: 20px 0 0 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: #a40303, $amount: 15);
    }
}

.stadistics__FiltrarSinDocumento {
    background-color: #434242;
    color: #FFF;
    width: 140px;
    margin: 20px 0 0 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: #a40303, $amount: 15);
    }
}

 .stadistics__arrowsContainer {
    width: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-left: -7%;
    margin-right: 1%;
    height: 25px;
}

.stadistics__arrows {
    font-size: 25px;
    color: darken($color: $primary, $amount: 15);
    transition: transform ease 0.4s;

    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }

}   

@media (max-width: 900px) {
    .stadistics__rangoFechas {
        width: 30%;
    }

    .stadistics__arrowsContainer {
        margin-left: -10.5%;
    }

}

@media (max-width: 600px) {


    .stadistics__rangoFechas {
        width: 50%;
        margin: 0;
    }

    .stadistics__arrowsContainer {
        margin-left: -14.5%;
    }
}


@media (max-width: 480px) {

    .stadistics__rangoContainer {
        flex-direction: column;
    }
    

    .stadistics__completosContainer {
        width: 100%;
        margin-left: 0;
    }

    .stadistics__completosTitle {
        font-size: 14px;
    }

    .stadistics__completosBodyFieldTitle {
        font-size: 14px;
    }

    .stadistics__completosBodyFieldData {
        font-size: 14px;
    }

    .stadistics__arrowsContainer {
        margin-left: 0;
        margin-right: 0;
    }

    .stadistics__FiltrarBtn {
        margin-left: 0;
    }

    .repartidorInput {
        margin-top: 20px;
    }

}