
// .dashboard__container {
//     background-color: lighten($color: $primary, $amount: 40);
//     min-height: 100vh;
// }
.dashboard__pedidos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

}
